import { useEffect } from 'react'

import { isRobot } from 'utils/deviceDetection'
import { useScriptInnerHTML } from 'utils/hooks'
import { shouldUsePublishedVersion } from 'utils/publishing'
import { waitFor } from 'utils/wait'

// Our custom script for PartnerStack for try.gamma.app
// See https://app.partnerstack.com/settings/integrations
const SCRIPT_INNER_HTML = `
(function() {var gs = document.createElement('script');gs.src = 'https://try.gamma.app/pr/js';gs.type = 'text/javascript';gs.async = 'true';gs.onload = gs.onreadystatechange = function() {var rs = this.readyState;if (rs && rs != 'complete' && rs != 'loaded') return;try {growsumo._initialize('pk_ie2Y6ptIgL4W0XJY9AC7qKBmxHlLMwqZ'); if (typeof(growsumoInit) === 'function') {growsumoInit();}} catch (e) {}};var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(gs, s);})();
`

export const usePartnerStackScript = (cb?: () => void) => {
  const enabled = !isRobot() && !shouldUsePublishedVersion()

  useScriptInnerHTML(enabled, SCRIPT_INNER_HTML)

  useEffect(() => {
    if (!cb) return

    waitFor(
      25,
      100
    )(() => window['growsumo']?.initialized === true)
      .then(cb)
      .catch(() => {
        console.warn('Timed out waiting for growsumo')
      })
  }, [cb])
}
