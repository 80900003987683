export const canonicalizeMarketingUrl = (url: string): string => {
  try {
    const parsedUrl = new URL(url)
    parsedUrl.protocol = 'https:'
    parsedUrl.hostname = parsedUrl.hostname.replace(/^www\./, '')

    // Remove query parameters, hash, and user authentication information
    parsedUrl.search = ''
    parsedUrl.hash = ''
    parsedUrl.username = ''
    parsedUrl.password = ''

    const excludeParts = ['marketing', 'en'] // Exclude these path segments
    parsedUrl.pathname = parsedUrl.pathname
      .split('/')
      .filter((part) => !excludeParts.includes(part))
      .join('/')

    // Remove consecutive slashes from the pathname
    parsedUrl.pathname = parsedUrl.pathname.replace(/\/+/g, '/')

    // Stringify and remove trailing slash for all paths, including root
    const stringifiedUrl: string = parsedUrl.href.toString().replace(/\/$/, '')

    return stringifiedUrl
  } catch {
    console.error(`Failed to canonicalize URL: ${url}`)
    return url
  }
}
