import { Messages, i18n } from '@lingui/core'
import { GetStaticPaths, GetStaticProps } from 'next'

import { SUPPORTED_LOCALE_KEYS, SupportedLocaleKey } from '../constants'
import { getSupportedLocaleKeyOrUseFallback } from '../utils/localeKey'

export async function loadCatalog(locale: string) {
  const catalog = await import(`translations/${locale}/messages`)
  return catalog.messages
}

export const getStaticPropsMarketing: GetStaticProps = async ({ params }) => {
  const locale = params?.locale
  const localeToUse = getSupportedLocaleKeyOrUseFallback(locale as string)
  const messages = await loadCatalog(localeToUse)

  return {
    props: {
      locale: localeToUse,
      messages,
    },
    // Revalidate every 24 hours at most
    revalidate: 60 * 60 * 24,
  }
}

export const getStaticPathsMarketing: GetStaticPaths = async () => {
  const localeKeys = SUPPORTED_LOCALE_KEYS

  const paths = localeKeys.map((locale) => {
    return {
      params: {
        locale,
      },
    }
  })
  return { paths, fallback: 'blocking' }
}

export function useLinguiInitSSG({
  locale,
  messages,
}: {
  locale: SupportedLocaleKey
  messages: Messages
}) {
  // This is functionally the equivalent of what we're doing in useLoadEnglishSync, but for SSG pages.
  const isClient = typeof window !== 'undefined'

  if (!isClient && locale !== i18n.locale) {
    // Server render
    i18n.loadAndActivate({ locale, messages })
  }
  if (isClient && !i18n.locale) {
    // First client render
    i18n.loadAndActivate({ locale, messages })
  }

  return i18n
}
