import { Global } from '@emotion/react'

import { config } from 'config'
import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'

import { FontPreloadLinkSet } from './FontPreloadLinkSet'
export const getEudoxusSansCSSString = (prefix: string = '') => `@font-face {
  font-family: 'Eudoxus Sans';
  src: url('${prefix}/fonts/EudoxusSans-Bold.woff2') format('woff2'),
    url('${prefix}/fonts/EudoxusSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('${prefix}/fonts/EudoxusSans-Regular.woff2') format('woff2'),
    url('${prefix}/fonts/EudoxusSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('${prefix}/fonts/EudoxusSans-Light.woff2') format('woff2'),
    url('${prefix}/fonts/EudoxusSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('${prefix}/fonts/EudoxusSans-ExtraBold.woff2') format('woff2'),
    url('${prefix}/fonts/EudoxusSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('${prefix}/fonts/EudoxusSans-ExtraLight.woff2') format('woff2'),
    url('${prefix}/fonts/EudoxusSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Eudoxus Sans';
  src: url('${prefix}/fonts/EudoxusSans-Medium.woff2') format('woff2'),
    url('${prefix}/fonts/EudoxusSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
`
export const EudoxusFontFiles = [
  '/fonts/EudoxusSans-Bold.woff',
  '/fonts/EudoxusSans-Bold.woff2',
  '/fonts/EudoxusSans-Regular.woff',
  '/fonts/EudoxusSans-Regular.woff2',
  '/fonts/EudoxusSans-Light.woff',
  '/fonts/EudoxusSans-Light.woff2',
  '/fonts/EudoxusSans-ExtraBold.woff',
  '/fonts/EudoxusSans-ExtraBold.woff2',
  '/fonts/EudoxusSans-ExtraLight.woff',
  '/fonts/EudoxusSans-ExtraLight.woff2',
  '/fonts/EudoxusSans-Medium.woff',
  '/fonts/EudoxusSans-Medium.woff2',
]

// This resolves CORS errors on published sites on custom domains.
export const EudoxusFontFilesAbsolute = EudoxusFontFiles.map((file) => {
  return `${config.FRONTEND_URL}${file}`
})
const Component = ({
  shouldUsePublishedVersion,
}: {
  shouldUsePublishedVersion?: boolean
}) => {
  const cssString = getEudoxusSansCSSString(
    shouldUsePublishedVersion ? config.FRONTEND_URL : ''
  )
  return (
    <>
      <Global styles={cssString} />
      <FontPreloadLinkSet
        files={
          shouldUsePublishedVersion
            ? EudoxusFontFilesAbsolute
            : EudoxusFontFiles
        }
      />
    </>
  )
}

export default Component
