import '../polyfills'

import { ApolloProvider } from '@apollo/client'
import { ChakraProvider, extendTheme, Theme } from '@chakra-ui/react'
import { config as fontawesomeConfig } from '@fortawesome/fontawesome-svg-core'
import { gammaTheme } from '@gamma-app/ui'
import { Messages } from '@lingui/core'
import { t } from '@lingui/macro'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Provider as ReduxProvider } from 'react-redux'

// SCSS must be included in the entrypoint file.
import '@fortawesome/fontawesome-svg-core/styles.css'

import { config } from 'config'
// Local modules
import { useBotDetection } from 'modules/abuse/hooks'
import {
  getReactQueryClient,
  initApolloClient,
  ReactQueryProvider,
} from 'modules/api'
import { CannyScript } from 'modules/canny/CannyScript'
import {
  FeatureFlagDevtoolBall,
  featureFlags,
  FeatureFlagWrapper,
} from 'modules/featureFlags'
import {
  DEFAULT_GAMMA_LOCALE,
  SUPPORTED_LOCALE_OPTIONS,
  SupportedLocaleKey,
} from 'modules/i18n/constants'
import { I18nProviderWrapperMarketing } from 'modules/i18n/I18nProviderWrapperMarketing'
import { init as initIntercom, IntercomScript } from 'modules/intercom'
import { setPageViewCookies } from 'modules/marketing_pages/utils/setPageViewCookies'
import { MonetizationContextProvider } from 'modules/monetization/context/MonetizationContextProvider'
import { usePartnerStackScript } from 'modules/partnerstack/usePartnerStackScript'
import { initPublicAPI } from 'modules/public_api'
import { getStore } from 'modules/redux'
import { analytics, SegmentContextProvider } from 'modules/segment'
import { recordSitePerformanceEvent } from 'modules/sites/performance'
import {
  TooltipPortalRefProvider,
  useCreateTooltipPortal,
} from 'modules/tooltips/hooks'
import { UserContextProvider } from 'modules/user'
import { canonicalizeMarketingUrl } from 'utils/canonicalizeMarketingUrl'
import { isRobot } from 'utils/deviceDetection'
import { useSSRInitialize } from 'utils/hooks/useSSRMounted'
import { shouldUsePublishedVersion } from 'utils/publishing'

// https://github.com/FortAwesome/react-fontawesome/blob/976c1adc59934b34e52b11c03dda4bd69831a6df/examples/nextjs-app/pages/_app.js#L4-L6
fontawesomeConfig.autoAddCss = false

const reduxStore = getStore()
// NOTE(jordan) if initApolloClient is not called alongside initOfflineMode then the cacheWarmLink will never be resolved
// thus we must always call with `supportOfflineMode: false` here
const apolloClient = initApolloClient({ supportOfflineMode: false })
const reactQueryClient = getReactQueryClient()
const finalTheme = extendTheme(gammaTheme, {
  styles: {
    global: {
      '.ProseMirror': {
        outline: '0 none',
      },
    },
  },
}) as Theme

if (typeof window !== 'undefined') {
  initPublicAPI(window, finalTheme)
  analytics.init()
  initIntercom()
  recordSitePerformanceEvent('appMainBundleLoaded')
  setPageViewCookies()
}

if (!featureFlags.get('debugLogging') && process.browser) {
  // Nuke all the calls to console.debug
  window.console.debug = () => {}
}

export const withGammaMarketingAppProviders = (
  Component: React.ComponentType<any>
) => {
  return function GammaMarketingAppHOC(props: any) {
    const { locale, messages, ...rest } = props
    return (
      <GammaMarketingApp locale={locale} messages={messages}>
        <Component {...rest} />
      </GammaMarketingApp>
    )
  }
}

export const GammaMarketingApp = ({
  children,
  locale,
  messages,
}: React.PropsWithChildren<{
  locale: SupportedLocaleKey
  messages: Messages
}>) => {
  useSSRInitialize()
  useBotDetection()
  usePartnerStackScript()

  const portalRef = useCreateTooltipPortal()

  return (
    <ReduxProvider store={reduxStore}>
      <I18nProviderWrapperMarketing
        key="gamma-i18n-provider"
        locale={locale}
        messages={messages}
      >
        <ChakraProvider theme={finalTheme} resetCSS={true}>
          <ApolloProvider client={apolloClient}>
            <ReactQueryProvider client={reactQueryClient}>
              <GammaHead />
              <IntercomScript />
              <CannyScript />
              <UserContextProvider>
                <SegmentContextProvider>
                  <FeatureFlagWrapper>
                    <MonetizationContextProvider>
                      <TooltipPortalRefProvider value={portalRef}>
                        {children}
                      </TooltipPortalRefProvider>
                    </MonetizationContextProvider>
                  </FeatureFlagWrapper>
                </SegmentContextProvider>
                <FeatureFlagDevtoolBall />
              </UserContextProvider>
            </ReactQueryProvider>
          </ApolloProvider>
        </ChakraProvider>
      </I18nProviderWrapperMarketing>
    </ReduxProvider>
  )
}

const stripPathSegments = (pathSegments): string[] => {
  // strip marketing/[locale] from the path
  // on serverside, these are [ 'marketing', 'en', 'pricing' ]
  // on client, these are ['pricing']
  if (pathSegments[0] === 'marketing') {
    pathSegments.shift()
    pathSegments.shift()
  }
  return pathSegments
}
const getHrefLangTags = ({ asPath }) => {
  const pathSegments = stripPathSegments(asPath.split('/').filter(Boolean)) // Split and remove any empty strings

  const firstSegment = pathSegments[0]
  if (Object.keys(SUPPORTED_LOCALE_OPTIONS).includes(firstSegment)) {
    pathSegments.shift() // Remove the locale prefix
  }
  const newPath = pathSegments.length > 0 ? `/${pathSegments.join('/')}` : ''

  return Object.keys(SUPPORTED_LOCALE_OPTIONS)
    .map((locale) => (
      <link
        key={locale}
        rel="alternate"
        hrefLang={locale}
        href={
          locale === DEFAULT_GAMMA_LOCALE
            ? `${config.FRONTEND_URL}${newPath}`
            : `${config.FRONTEND_URL}/${locale}${newPath}`
        }
      />
    ))
    .concat(
      <link
        key="default"
        rel="alternate"
        hrefLang={'x-default'}
        href={`${config.FRONTEND_URL}${newPath}`}
      />
    )
}

const GammaHead = () => {
  const { asPath } = useRouter()
  // This appears to need to be inline in order for it to work.
  const SITE_TITLE = t`Presentations and Slide Decks with AI | Gamma`
  const OG_DESCRIPTION = t`Made with Gamma. A new medium for presenting ideas, powered by AI.`
  const APP_ENV = config.APPLICATION_ENVIRONMENT
  const faviconPath =
    APP_ENV === 'production'
      ? '/favicons/favicon-192.svg'
      : APP_ENV === 'staging'
      ? '/favicons/favicon_staging.jpg'
      : '/favicons/favicon_local.jpg'

  const hreflangTags = getHrefLangTags({ asPath })
  const canonicalUrl = canonicalizeMarketingUrl(
    `${config.FRONTEND_URL}${asPath}`
  )

  return (
    <Head>
      <title>{SITE_TITLE}</title>
      {/* This needs to be here instead of _document.tsx to prevent Next overriding with its own value https://nextjs.org/docs/messages/no-document-viewport-meta */}
      <link rel="canonical" href={canonicalUrl} key="canonical" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="shortcut icon" href={faviconPath} />
      <meta content={OG_DESCRIPTION} name="description" key="description" />
      <meta content="Gamma" property="og:title" key="og:title" />
      <meta
        content={OG_DESCRIPTION}
        property="og:description"
        key="og:description"
      />
      <meta content={config.OG_IMAGE_URL} property="og:image" key="og:image" />
      <meta content="Gamma" property="twitter:title" key="twitter:title" />
      <meta
        content={OG_DESCRIPTION}
        property="twitter:description"
        key="twitter:description"
      />
      <meta
        content={config.OG_IMAGE_URL}
        property="twitter:image"
        key="twitter:image"
      />
      <meta property="og:type" content="website" key="og:type" />
      <meta
        content="summary_large_image"
        name="twitter:card"
        key="twitter:card"
      />
      {/* Prevent Google from showing Google Translate in address bar */}
      <meta name="google" content="notranslate" />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="https://use.typekit.net/tiw2cwq.css"></link>
      {/** Hardcode this for ease until we replace with LD */}
      {!isRobot() && !shouldUsePublishedVersion() && (
        <script
          async
          src="https://cdn.optimizely.com/js/20579410255.js"
        ></script>
      )}
      {hreflangTags}
    </Head>
  )
}
