import jsCookie from 'js-cookie'

const isInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const setPageViewCookies = () => {
  try {
    // Check if the referrer is from a different domain
    if (document.referrer && document.referrer.indexOf('gamma.app') === -1) {
      jsCookie.set('lastReferrer', document.referrer, { expires: 30 }) // Set the cookie for 30 days
    }

    // Check if this is the first page seen on your domain. Exclude iframed sites for now.
    if (
      !jsCookie.get('firstPageSeen') &&
      window.location.hostname === 'gamma.app' &&
      !isInIframe()
    ) {
      jsCookie.set('firstPageSeen', window.location.href, { expires: 365 }) // Set the cookie for 1 year
    }
  } catch (e) {
    // swallow
  }
}
